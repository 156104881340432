<!-- src/components/Profile.vue -->

<template src="@/../../../templates/brand/components/templates/shop/Programs.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
import countDown from '@/components/Countdown.vue'
import Carousel from '@/components/pCarousel.vue'
import Kramed from 'kramed'

export default {
  name: 'Programs',
  created: function () {
    this.getCollections()
  },
  components: { countDown, Carousel },
  data () {
    return {
      locales: _.keys(this.$store.state.lang),
      items: {
        en: [
          {
            src: '/assets/static/img/banner-set1.jpg',
            alt: 'image-1'
          },
          {
            src: '../../assets/static/img/banner-set2.jpg',
            alt: 'image-2'
          }
        ],
        fr: [
          {
            src: '/assets/static/img/banner-fr-set1.jpg',
            alt: 'fr-image-1'
          },
          {
            src: '../../assets/static/img/banner-fr-set2.jpg',
            alt: 'fr-image-2'
          }
        ]
      },
      kramed: Kramed
    }
  },
  computed: {
    ...mapState({
      programs: ({ Program }) => _.sortBy(item => item.order, Program.programs),
      user: ({ App }) => App.user
    })
  },
  methods: {
    ...mapActions(['getPrograms']),
    showFlash: function (flashes, flash) {
      if (flashes && flashes[flash]) {
        return flashes[flash][this.$i18n.locale]
      }
    },
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/Programs.css"></style>
